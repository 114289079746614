////////////////////////
// Default the Breakpoints variable
////////////////////////
$breakpoints: () !default;
$BREAKPOINTS: () !default;

////////////////////////
// Respond-to API Mixin
////////////////////////
@mixin respond-to($context, $no-query: false) {
 @if length($breakpoints)> 0 and length($BREAKPOINTS) == 0 {
 @warn "In order to avoid variable namespace collisions, we have updated the way to add breakpoints for respond-to. Please change all instances of `$breakpoints: add-breakpoint()` to `@include add-breakpoint()`. The `add-breakpoint()` function will be deprecated in a future release.";
 $BREAKPOINTS: $breakpoints !global;
 $breakpoints: () !global;
 }

 @if type-of($BREAKPOINTS) != 'map' {
 // Just in case someone writes gibberish to the $breakpoints variable.
 @warn "Your breakpoints aren't a map! See https://github.com/snugug/respond-to#api if you'd like a reminder on how to use Respond-to";
 @content;
 }
 @else if map-has-key($BREAKPOINTS, $context) {
 @include breakpoint(map-get($BREAKPOINTS, $context), $no-query) {
 @content;
 }
 }
 @else if not map-has-key($BREAKPOINTS, $context) {
 @warn "`#{$context}` isn't a defined breakpoint! Please add it using `$breakpoints: add-breakpoint(`#{$context}`, $value);`";
 @content;
 }
 @else {
 @warn "You haven't created any breakpoints yet! Make some already! See https://github.com/snugug/respond-to#api if you'd like a reminder on how to use Respond-to";
 @content;
 }
}

//////////////////////////////
// Add Breakpoint to Breakpoints
// TODO: Remove function in next release
//////////////////////////////
@function add-breakpoint($name, $bkpt, $overwrite: false) {
 $output: ($name: $bkpt);

 @if length($breakpoints) == 0 {
 @return $output;
 }
 @else {
 @if map-has-key($breakpoints, $name) and $overwrite != true {
 @warn "You already have a breakpoint named `#{$name}`, please choose another breakpoint name, or pass in `$overwrite: true` to overwrite the previous breakpoint.";
 @return $breakpoints;
 }
 @else if not map-has-key($breakpoints, $name) or $overwrite == true {
 @return map-merge($breakpoints, $output);
 }
 }
}

@mixin add-breakpoint($name, $bkpt, $overwrite: false) {
 $output: ($name: $bkpt);

 @if length($BREAKPOINTS) == 0 {
 $BREAKPOINTS: $output !global;
 }
 @else {
 @if map-has-key($BREAKPOINTS, $name) and $overwrite != true {
 @warn "You already have a breakpoint named `#{$name}`, please choose another breakpoint name, or pass in `$overwrite: true` to overwrite the previous breakpoint.";
 $BREAKPOINTS: $BREAKPOINTS !global;
 }
 @else if not map-has-key($BREAKPOINTS, $name) or $overwrite == true {
 $BREAKPOINTS: map-merge($BREAKPOINTS, $output) !global;
 }
 }
}