@function breakpoint-parse-double-default($first, $second) {
 $feature: '';
 $value: '';

 @if type-of($first) == 'string' {
 $feature: $first;
 $value: $second;
 }
 @else {
 $feature: $second;
 $value: $first;
 }

 // Set Context
 $context-setter: private-breakpoint-set-context($feature, $value);

 @if (breakpoint-get('to ems') == true) {
 $value: breakpoint-to-base-em($value);
 }

 @return '(#{$feature}: #{$value})'
}
