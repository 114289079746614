// Color variables
// - Cool
// - Warm
// - Gray Scale
// 
// clrs, from https://github.com/mrmrs/colors

// Cool
$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;

// Warm
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;

// Gray Scale
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
