$asset-url: "https://cdn.robotika.ax";
$site-url: "https://www.robotika.ax";
$font-stack: Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad,
 "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial,
 sans-serif;
$mobile-breakpoint: 767px;
$tablet-breakpoint: 768px;
$laptop-breakpoint: 1024px;
//@import "milkshake";
@import "picnic";

// Mixins
@mixin gradient($deg, $from, $to) {
 background: -moz-linear-gradient($deg, $from, $to);
 background: -webkit-linear-gradient($deg, $from, $to);
 background: -o-linear-gradient($deg, $from, $to);
 background: linear-gradient($deg, $from, $to);
}

@mixin skew_and_rotate($skew, $rotate) {
 -moz-transform: rotateZ($rotate) skewX($skew);
 -webkit-transform: rotateZ($rotate) skewX($skew);
 -o-transform: rotateZ($rotate) skewX($skew);
 transform: rotateZ($rotate) skewX($skew);
}

// General styles
html,
body {
 font-family: $font-stack;
}

p {
 hyphens: auto;
}

// Brand
.brand {
 font-weight: normal;
 font-style: normal;
 font-size: 1.6em;
 font-family: "milkshakeregular", cursive;
}

// Navigation
%nav {
 position: fixed;

 &.transparent {
 box-shadow: none;
 background: none;

 .site-title {
 color: $picnic-black;
 font-size: 2.6em;
 padding-left: 0;

 @media screen and (max-width: $mobile-breakpoint) {
 display: none;
 }
 }

 .pseudo.button {
 color: $picnic-black;
 }

 .menu {
 .page-link {
 color: $picnic-black;

 @media screen and (max-width: $mobile-breakpoint) {
 color: $picnic-white;
 }
 }
 }
 }

 .menu {
 .page-link {
 @media screen and (max-width: $mobile-breakpoint) {
 color: $picnic-white;
 }

 &.butik {
 font-weight: bold;

 @media screen and (max-width: $mobile-breakpoint) {
 font-weight: normal;
 }
 }
 }

 @media screen and (max-width: $mobile-breakpoint) {
 background: $picnic-black;
 }
 }

 .site-title {
 font-weight: normal;
 }

 .brand,
 .pseudo.button {
 color: inherit;
 }

 .show ~ .menu {
 @media screen and (max-width: 60em) {
 background: $picnic-black;
 }
 }

 .show:checked ~ .burger {
 color: transparent;
 }

 &.imponent {
 padding: 2em 0;
 }
}

.imponent .logo {
 height: 3em;
}

// Wrapper
.page-content {
 padding: 80px 0 0;
 max-width: 1000px;
 margin: 0 auto;

 &.home {
 padding-top: 0;
 max-width: 100%;
 }
}

// Posts/pages
.post {
 margin-left: 0;
 padding-right: 0.6em;
}

.page-content {
 min-height: 50vh;

 @media screen and (max-width: $mobile-breakpoint) {
 ul {
 padding-left: 0.6em;
 }
 }
}

// Hero
.hero {
 position: relative;
 width: 100%;
 height: 97vh;
 backdrop-filter: blur(1px);
 @media screen and (max-width: $mobile-breakpoint) {
 text-align: center;
 }

 min-height: 300px;
 color: $picnic-black;
 padding: 1px 0;
 background-image: url("https://images.robotika.ax/assets/images/robot_holds_pen_writes_in_diary-1920.jpg");
 background-position: center center;
 background-repeat: no-repeat;
 background-size: cover;

 @media screen and (max-width: $mobile-breakpoint) {
 height: 50vh;
 }

 @media screen and (max-width: 720px) and (min-device-pixel-ratio: 1) {
 background-image: url("https://images.robotika.ax/assets/images/robot_holds_pen_writes_in_diary-720.jpg");
 }
 @media screen and (min-width: 721px) and (max-width: 1280px) and (min-device-pixel-ratio: 1) {
 background-image: url("https://images.robotika.ax/assets/images/robot_holds_pen_writes_in_diary-1280.jpg");
 }

 @media only screen and (-webkit-min-device-pixel-ratio: 2),
 only screen and (min--moz-device-pixel-ratio: 2),
 only screen and (-o-min-device-pixel-ratio: 2/1),
 only screen and (min-device-pixel-ratio: 2),
 only screen and (min-resolution: 192dpi),
 only screen and (min-resolution: 2dppx) {
 background-image: url("https://images.robotika.ax/assets/images/robot_holds_pen_writes_in_diary-1920.jpg");
 }
}

.hero-content {
 position: relative;

 //top: 50%
 //transform: translateY(-50%)
 top: 3em;
 padding-left: 0.6em;

 .hero-site-title,
 .hero-site-subtitle {
 color: $picnic-black;
 text-shadow: $picnic-shadow;
 font-weight: normal;
 }

 .hero-site-title {
 display: none;
 font-family: "milkshakeregular", cursive;
 font-style: normal;
 font-size: 3.1416em * 1.5;
 padding-bottom: 0.1em;
 display: none;

 @media screen and (max-width: $mobile-breakpoint) {
 font-size: 3.1416em;

 &.transparent {
 display: block;
 }
 }
 }

 .hero-site-subtitle {
 padding-top: 0.1em;
 display: none;

 &.transparent {
 display: block;
 }

 @media screen and (max-width: $mobile-breakpoint) {
 font-size: 1.2em;
 }
 }
}

// Home blocks
.home-block {
 padding-right: 0.6em;
 padding-top: 0.6em;
 position: relative;

 @include skew_and_rotate(-1deg, -1deg);

 &.intro {
 font-size: 1.2em;
 @include gradient(90deg, $lime, $teal);
 top: -0.7em;
 }

 &.consulting,
 &.electronics,
 &.education {
 a,
 a:visited,
 a:hover,
 a:active {
 color: $picnic-white;
 font-weight: bold;
 overflow-wrap: normal;
 }
 }

 &.consulting {
 @include gradient(45deg, $orange, $fuchsia);
 color: $picnic-white;
 top: -1.2em;
 }

 &.education {
 @include gradient(45deg, $purple, $maroon);
 color: $picnic-white;
 top: -0.9em;
 }

 &.electronics {
 color: $picnic-white;
 @include gradient(45deg, $blue, $navy);
 top: -1em;
 }

 .home-block-content {
 max-width: 1000px;
 margin: 0 auto;
 font-weight: normal;

 @include skew_and_rotate(1deg, 1deg);

 &.introduction {
 max-width: 90%;
 }

 .centered {
 text-align: center;
 }

 p {
 text-align: justify;

 &.ingress {
 font-weight: bold;
 }
 }

 .large {
 font-size: 110%;
 }
 }
}

p {
 &.large {
 font-size: 110%;
 }
}

// Footer
.site-footer {
 font-size: 90%;

 //border-top: $picnic-border
 background-color: $picnic-black;
 color: $picnic-white;
 margin-left: 0;

 .footer-heading {
 margin-left: 0.6em;
 padding-bottom: 0;
 font-weight: normal;
 font-family: "milkshakeregular", cursive;
 font-style: normal;
 }

 data {
 height: 0;
 display: none;
 }

 .footer-description {
 padding-left: 0.6em;
 padding-right: 0.6em;
 }
}

.contact-list,
.social-media-list {
 color: $picnic-white;
 list-style: none;
 margin-left: 0;

 .svg-icon {
 fill: $picnic-white;
 }
}

body.home {
 background: $picnic-black;
}

/**
 * Icons */
.svg-icon {
 width: 16px;
 height: 16px;
 display: inline-block;
 fill: #{$black};
 margin-right: 0.6em;
 vertical-align: text-top;
}

.social-media-list {
 li + li {
 padding-top: 5px;
 }
}

// Store
.card {
 &.product {
 p {
 font-size: 90%;
 }

 img {
 &.product-image {
 max-width: 400px;
 }
 }

 .button {
 &.read-more {
 float: right;
 }
 }
 }
}

.flex {
 &.product {
 .card {
 .price-tag {
 float: right;
 }
 }
 }
}

// Logos
img {
 &.logo {
 &.kitronik {
 max-width: 96px;
 }

 &.low {
 max-height: 1em;
 }
 }
}

.brands {
 .logotype {
 @media screen and (min-width: $tablet-breakpoint) {
 position: relative;
 top: 45%;
 transform: translateY(-50%);
 width: 100%;
 }

 svg {
 path {
 fill: #{$black} !important;
 }
 }
 }
}

// Ordering and stuff
.initially-hidden {
 display: none;
}

input[type="image"] {
 border: none;
 width: auto;
 height: auto;
 background: transparent;
}

// Tables
table {
 tbody {
 td:last-child {
 padding-right: 0.6em;
 }
 }
}

picture.portrait {
 float: right;
 max-width: 300px;
 @media screen and (max-width: $mobile-breakpoint) {
 float: none;
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 max-width: 270px;
 }

 img {
 max-width: 300px;
 @media screen and (max-width: $mobile-breakpoint) {
 max-width: 270px;
 }
 @media screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
 padding-right: 0.6em;
 }
 }
}

.post {
 img {
 @media screen and (max-width: $mobile-breakpoint) {
 width: 100%;
 max-width: 200px;
 }
 }
}
