@import "resolution/resolution";

@function breakpoint-build-resolution($query-print, $query-resolution, $empty-media, $first) {
 $leader: '';
 // If we're forcing
 @if not ($empty-media) or not ($first) {
 $leader: 'and ';
 }

 @if breakpoint-get('transform resolutions') and $query-resolution {
 $resolutions: breakpoint-make-resolutions($query-resolution);
 $length: length($resolutions);
 $query-holder: '';

 @for $i from 1 through $length {
 $query: '#{$query-print} #{$leader}#{nth($resolutions, $i)}';
 @if $i == 1 {
 $query-holder: $query;
 }
 @else {
 $query-holder: '#{$query-holder}, #{$query}';
 }
 }

 @return $query-holder;
 }
 @else {
 // Return with attached resolution
 @return $query-print;
 }
}
