// Navigation bar

$picnic-nav-height: 3em !default;
$picnic-nav-shadow: $picnic-shadow !default;
$picnic-nav-position: fixed !default;
$picnic-nav-sides: .6em !default;
$picnic-nav-responsive: true !default;
$picnic-nav-open-left: false !default;
$picnic-nav-timing: .5s !default;

// Vertical align based on
// http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
%nav {

 // Position it on the top, full width
 position: $picnic-nav-position;
 top: 0;
 left: 0;
 right: 0;

 // Total height
 height: $picnic-nav-height;
 padding: 0 $picnic-nav-sides;

 // Make it look like a hovering bar
 background: #fff;
 box-shadow: $picnic-nav-shadow;
 z-index: 10000;

 // Everything happens slow-ish
 transition: all .3s;

 // Half-pixel fix (vertical align hack)
 transform-style: preserve-3d;

 // The two main elements
 .brand,
 .menu,
 .burger {
 float: right;

 position: relative;
 top: 50%;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 }

 .brand {
 font-weight: 700;
 float: left;
 padding: 0 $picnic-nav-sides;
 max-width: 50%;
 white-space: nowrap;
 color: inherit;

 * {
 vertical-align: middle;
 }
 }

 .logo {
 height: 2em;
 margin-right: .3em;
 }

 .select::after {
 height: calc(100% - 1px);
 padding: 0;
 line-height: 2.4em;
 }

 .menu > * {
 margin-right: $picnic-nav-sides;
 }
}
