// Inherit from the class
@import 'class';


// For the toggle buttons
:checked + .toggle,
:checked + .toggle:hover {
 box-shadow: $picnic-button-click;
}

// Hide the checkbox/radiobutton
// Handle with care
[type] + .toggle {
 padding: $picnic-button-padding;
 margin-right: 0;

 &:after,
 &:before {
 display: none;
 }
}

// Implement the abstract class for real elements
button,
.button,
[type=submit] {
 @extend %button;
}
