* {
 box-sizing: inherit;
}

html,
body {
 font-family: Arial, Helvetica, sans-serif;
 box-sizing: border-box;
 height: 100%;
}

body {
 color: $picnic-black;
 font-size: 1.1em;
 line-height: 1.5;
 background: $picnic-white;
}

main {
 display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
 margin: 0;
 padding: $picnic-separation 0;
}

li {
 margin: 0 0 $picnic-separation / 2;
}

a {
 color: $picnic-primary;
 text-decoration: none;
 box-shadow: none;
 transition: $picnic-transition;
}


// The <pre> doesn't have a nice style from Normalize.css

code {
 padding: $picnic-separation / 2 $picnic-separation;
 font-size: .8em;
 background: #f5f5f5;
}

pre {
 text-align: left;
 padding: $picnic-separation / 2 $picnic-separation;
 background: #f5f5f5;
 border-radius: $picnic-radius;

 code {
 padding: 0;
 }
}

blockquote {
 padding: 0 0 0 1em;
 margin: 0 0 0 .1em;
 box-shadow: inset 5px 0 rgba($picnic-black, .3);
}

label {
 cursor: pointer;
}
