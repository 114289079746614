@function breakpoint-parse-double-string($first, $second) {
 $feature: '';
 $value: '';

 // Test to see which is the feature and which is the value
 @if (breakpoint-string-value($first) == true) {
 $feature: $first;
 $value: $second;
 }
 @else {
 $feature: $second;
 $value: $first;
 }

 // Set Context
 $context-setter: private-breakpoint-set-context($feature, $value);

 @return '(#{$feature}: #{$value})';
}
