// %button
// This class defines a clickable element that looks like a label



// Variables
$picnic-button-margin: $picnic-separation / 2 0 !default;
$picnic-button-padding: $picnic-label-padding !default;
$picnic-button-hover: inset 0 0 0 99em rgba($picnic-white, $picnic-transparency) !default;
$picnic-button-click: inset 0 0 0 99em rgba($picnic-black, $picnic-transparency) !default;
$picnic-button-pseudo-hover: inset 0 0 0 99em rgba($picnic-black, $picnic-transparency / 2) !default;
$picnic-button-transition: $picnic-transition !default;
$picnic-button-radius: $picnic-label-radius !default;



// Definition
%button {
 @extend %label;

 margin: $picnic-button-margin;
 cursor: pointer;
 transition: $picnic-button-transition;
 border-radius: $picnic-button-radius;

 // Avoid the input { height: 2.2 }
 height: auto;
 vertical-align: baseline;

 // This avoids a nasty "hover" issue (slightly taller on hover)
 box-shadow: 0 0 rgba(0, 0, 0, 0) inset;

 // Methods
 &:hover,
 &:focus {
 box-shadow: $picnic-button-hover;
 border: 0;
 }

 &.pseudo:hover,
 &.pseudo:focus {
 box-shadow: $picnic-button-pseudo-hover;
 }

 &.active,
 &:active,
 &.pseudo:active {
 box-shadow: $picnic-button-click;
 }

 &[disabled] {
 cursor: default;
 box-shadow: none;
 background: #bbb;
 }
 }


// // Note: If you try to do this there's a selector explossion:

// &%hover {
// box-shadow: $picnic-button-hover;
// }

// &:hover {
// @extend %hover;
// }

// Like `button.button:hover, .button[type="submit"], ...`
