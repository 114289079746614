// Label of text
$picnic-label-padding: $picnic-separation / 2 $picnic-separation * 1.5 !default;
$picnic-label-radius: $picnic-radius !default;

// Styles
%label {
 display: inline-block;
 text-align: center;
 letter-spacing: inherit;
 margin: 0;
 padding: $picnic-label-padding;
 vertical-align: middle;
 background: $picnic-primary;
 color: $picnic-white;
 border: 0;
 border-radius: $picnic-label-radius;
 width: auto;

 -webkit-touch-callout: none;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;

 // Colors
 &.success {
 background: $picnic-success;
 }

 &.warning {
 background: $picnic-warning;
 }

 &.error {
 background: $picnic-error;
 }

 &.pseudo {
 background-color: transparent;
 color: inherit;
 }
}
