//////////////////////////////
// Private Breakpoint Variables
//////////////////////////////
$private-breakpoint-context-holder: ();
$private-breakpoint-query-count: 0 !default;

//////////////////////////////
// Breakpoint Has Context
// Returns whether or not you are inside a Breakpoint query
//////////////////////////////
@function breakpoint-has-context() {
 @if length($private-breakpoint-query-count) {
 @return true;
 }
 @else {
 @return false;
 }
}

//////////////////////////////
// Breakpoint Get Context
// $feature: Input feature to get it's current MQ context. Returns false if no context
//////////////////////////////
@function breakpoint-get-context($feature) {
 @if map-has-key($private-breakpoint-context-holder, $feature) {
 $get: map-get($private-breakpoint-context-holder, $feature);
 // Special handling of no-query from get side so /false/ prepends aren't returned
 @if $feature == 'no-query' {
 @if type-of($get) == 'list' and length($get) > 1 and nth($get, 1) == false {
 $get: nth($get, length($get));
 }
 }
 @return $get;
 }
 @else {
 @if breakpoint-has-context() and $feature == 'media' {
 @return breakpoint-get('default media');
 }
 @else {
 @return false;
 }
 }
}

//////////////////////////////
// Private function to set context
//////////////////////////////
@function private-breakpoint-set-context($feature, $value) {
 @if $value == 'monochrome' {
 $feature: 'monochrome';
 }

 $current: map-get($private-breakpoint-context-holder, $feature);
 @if $current and length($current) == $private-breakpoint-query-count {
 @warn "You have already queried against `#{$feature}`. Unexpected things may happen if you query against the same feature more than once in the same `and` query. Breakpoint is overwriting the current context with `#{$value}`";
 }

 @if not map-has-key($private-breakpoint-context-holder, $feature) {
 $v-holder: ();
 @for $i from 1 to $private-breakpoint-query-count {
 @if $feature == 'media' {
 $v-holder: append($v-holder, breakpoint-get('default media'));
 }
 @else {
 $v-holder: append($v-holder, false);
 }
 }
 $v-holder: append($v-holder, $value);
 $private-breakpoint-context-holder: map-merge($private-breakpoint-context-holder, ($feature: $v-holder)) !global;
 }
 @else {
 $v-holder: map-get($private-breakpoint-context-holder, $feature);
 $length: length($v-holder);
 @for $i from $length to $private-breakpoint-query-count - 1 {
 @if $feature == 'media' {
 $v-holder: append($v-holder, breakpoint-get('default media'));
 }
 @else {
 $v-holder: append($v-holder, false);
 }
 }
 $v-holder: append($v-holder, $value);
 $private-breakpoint-context-holder: map-merge($private-breakpoint-context-holder, ($feature: $v-holder)) !global;
 }

 @return true;
}

//////////////////////////////
// Private function to reset context
//////////////////////////////
@mixin private-breakpoint-reset-contexts {
 $private-breakpoint-context-holder: () !global;
 $private-breakpoint-query-count: 0 !global;
}