table {
 text-align: left;
 }

td,
th {
 padding: $picnic-separation / 2 2.4em $picnic-separation / 2 $picnic-separation;
 }

th {
 text-align: left;
 font-weight: 900;
 color: $picnic-white;
 background-color: $picnic-primary;
 }

.success th {
 background-color: $picnic-success;
 }

.warning th {
 background-color: $picnic-warning;
 }

.error th {
 background-color: $picnic-error;
 }

.dull th {
 background-color: $picnic-dull;
 }

// Zebra stripes
tr:nth-child(even) {
 background: rgba(0, 0, 0, .05);
 }
